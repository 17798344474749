import React from "react";
const Disclaimer = () => {
  return (
    <div
      style={{
        backgroundImage: `url('/Assets/bg1.png')`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
      className="px-[12%] lg:py-[10%] pb-[20%] pt-[30%]  relative bg-secondary"
    >
      <h1 className="text-3xl font-bold mb-6 text-blue">Disclaimer</h1>
      <p className="mb-4 text-justify">
        <strong>Last updated:</strong> December 5, 2024
      </p>
      <p className="mb-4 text-justify">
        Welcome to Learn French with Anks{" "}
        <a className="text-blue" href="https://www.learnfrenchwithanks.com/">
          (https://www.learnfrenchwithanks.com/)
        </a>
        . This Disclaimer page outlines important information regarding our
        services, courses, and content. By accessing or using this website, you
        agree to the terms of this disclaimer.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        1. Independent Platform
      </h2>
      <p className="mb-4 text-justify">
        Learn French with Anks is an independent platform solely dedicated to
        offering French language courses. We are not affiliated, partnered, or
        connected with any institution, school, university, or third-party
        organization. All courses available on this platform are designed and
        provided by Ankita Agrawal, Founder of Learn French With Anks Inc.
      </p>
      {/* <ul className="list-disc ml-6 mb-4">
        <li>Name</li>
        <li>Email Address</li>
        <li>Phone Number</li>
        <li>Password (set by the user)</li>
      </ul> */}
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        {" "}
        2. Accuracy of Information
      </h2>
      <p className="mb-4 text-justify">
        We strive to provide accurate, up-to-date, and reliable information about
        our French courses and services. However, we do not guarantee the
        completeness or accuracy of the content on this website. The material
        provided is for general informational purposes only and is subject to
        change without notice.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        3. Purchase of Courses
      </h2>
      <p className="mb-4 text-justify">
        Our courses and services are exclusively available for purchase on our
        official course store at Topmate. We are not responsible for any
        transactions or courses offered on platforms other than the specified
        link.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        {" "}
        4. No Guarantees
      </h2>
      <p className="mb-4 text-justify">
        While we aim to deliver high-quality language education, we do not
        guarantee specific outcomes or proficiency levels upon completing any of
        our courses. The results depend on individual effort, practice, and
        commitment.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        {" "}
        5. External Links
      </h2>
      <p className="mb-4 text-justify">
        This website may contain links to third-party websites for your
        convenience. We are not responsible for the content, accuracy, or
        reliability of external sites. Accessing these links is at your own
        risk.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        {" "}
        6. Intellectual Property
      </h2>
      <p className="mb-4 text-justify">
        All content on this website, including text, graphics, images, videos,
        and other materials, is the intellectual property of Learn French with
        Anks unless otherwise stated. Unauthorized use, reproduction, or
        distribution of our materials is strictly prohibited.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        {" "}
        7. Limitation of Liability
      </h2>
      <p className="mb-4 text-justify">
        Learn French with Anks shall not be held liable for any direct,
        indirect, incidental, or consequential damages resulting from the use of
        our website, courses, or services. This includes but is not limited to
        technical issues, misinterpretations, or data inaccuracies.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        {" "}
        8. Changes to Disclaimer
      </h2>
      <p className="mb-4 text-justify">
        We reserve the right to update or modify this Disclaimer at any time
        without prior notice. It is your responsibility to review this page
        periodically for changes. Continued use of our website constitutes
        acceptance of the updated terms.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">Contact Us</h2>
      <p className="mb-4 text-justify">
      If you have any questions or concerns about this Disclaimer, please contact us at:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Email: <strong>learnfrenchwithanks@gmail.com</strong>
        </li>
        <li>
          Phone: <strong>+1 437 733 5551</strong>
        </li>
      </ul>
    </div>
  );
};

export default Disclaimer;
