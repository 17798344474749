import React from "react";
const PrivacyPolicy = () => {
  return (
    <div
      style={{
        backgroundImage: `url('/Assets/bg1.png')`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
      className="px-[12%] lg:py-[10%] pb-[20%] pt-[30%]  relative bg-secondary"
    >
      <h1 className="text-3xl font-bold mb-6 text-blue">Privacy Policy</h1>
      <p className="mb-4 text-justify">
        <strong>Last updated:</strong> December 5, 2024
      </p>
      <p className="mb-4 text-justify">
        Welcome to Learn French with Anks! Your privacy is very important to us,
        and we are committed to protecting the personal information you share
        with us. This Privacy Policy outlines how we collect, use, and safeguard
        your data.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">1. Who We Are</h2>
      <p className="mb-4 text-justify">
        We, Learn French with Anks, operate as an individual-owned platform,
        providing French language courses worldwide. We are not connected,
        affiliated, or partnered with any institute, university, or school.
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Our main website:{" "}
          <a className="text-blue" href="https://www.learnfrenchwithanks.com">
            https://www.learnfrenchwithanks.com
          </a>
        </li>
        <li>
          Our course store:{" "}
          <a
            className="text-blue"
            href="https://topmate.io/learnfrenchwithanks"
          >
            https://topmate.io/learnfrenchwithanks
          </a>
        </li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        {" "}
        2. Information We Collect
      </h2>
      <p className="mb-4 text-justify">
        We collect the following types of information:
        <ul className="list-disc ml-6 mb-4">
          <li>
            Personal Information: When you register for a course, contact us, or
            subscribe to our newsletter, we may collect your name, email
            address, phone number, and billing details.
          </li>
          <li>
            Non-Personal Information: This includes browser type, IP address,
            and website usage data collected through cookies or analytics tools.
          </li>
        </ul>
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        3. How We Use Your Information
      </h2>
      <p className="mb-4 text-justify">
        We use your information to:
        <li>Process course purchases and manage your account.</li>
        <li> Respond to your queries or feedback.</li>
        <li>
          Send updates about our courses, promotions, or news (only if you have
          opted in).
        </li>
        <li> Improve our website and services through analytics.</li>
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        {" "}
        4. How We Share Your Information
      </h2>
      <p className="mb-4 text-justify">
        We donot sell, rent, or trade your personal data to third parties.
        However, we may share your information with:
        <li>
          {" "}
          Service Providers: Payment gateways or platforms like Topmate for
          processing transactions.
        </li>
        <li>
          {" "}
          Legal Authorities: If required by law or to protect our rights.
        </li>
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        {" "}
        5. Cookies and Tracking
      </h2>
      <p className="mb-4 text-justify">
        We use cookies to enhance your browsing experience. Cookies help us:
        <li> Analyze website traffic and behavior.</li>
        <li>Remember your preferences during your visits.</li>
        You can disable cookies through your browser settings; however, doing so
        may affect website functionality.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        {" "}
        6. Third-Party Links
      </h2>
      <p className="mb-4 text-justify">
        Our website may contain links to external platforms, such as our course
        store at
        <a href="https://topmate.io/learnfrenchwithanks">
          https://topmate.io/learnfrenchwithanks
        </a>
        . We are not responsible for the privacy practices of third-party
        websites, and we encourage you to review their policies.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        {" "}
        7. Data Security
      </h2>
      <p className="mb-4 text-justify">
        We implement industry-standard security measures to protect your personal
        data from unauthorized access, alteration, or misuse. However, no online
        platform can guarantee absolute security.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue"> 8. Your Rights</h2>
      <p className="mb-4 text-justify">
        You have the right to:
        <li>Access, update, or delete your personal information.</li>
        <li>Opt-out of receiving marketing emails.</li>
        <li>Request information about how we use your data.</li>
        To exercise these rights, contact us at learnfrenchwithanks@gmail.com
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        {" "}
        9. Children’s Privacy
      </h2>
      <p className="mb-4 text-justify">
        Our services are not intended for children under 13 years of age. We do
        not knowingly collect personal data from children.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        {" "}
        10. Changes to This Policy
      </h2>
      <p className="mb-4 text-justify">
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page with an updated “Last Updated” date.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">Contact Us</h2>
      <p className="mb-4 text-justify">
        If you have any questions or concerns about this Privacy Policy or how
        your information is handled, please contact us:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Email: <strong>learnfrenchwithanks@gmail.com</strong>
        </li>
        <li>
          Website:{" "}
          <a href="https://www.learnfrenchwithanks.com">
            <strong>https://www.learnfrenchwithanks.com</strong>
          </a>
        </li>
      </ul>
      By using our website and purchasing our courses, you agree to this Privacy
      Policy.
    </div>
  );
};

export default PrivacyPolicy;
