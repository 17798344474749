import React from "react";
import { MdLocationOn } from "react-icons/md";
// import { IoIosCall } from "react-icons/io";
import { IoIosMail } from "react-icons/io";
import { NavLink } from "react-router-dom";
// import { Divider } from "@mui/material";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const connectButton = document.getElementsByClassName('topmate-button');
  // console.log(connectButton);

  const connectOpen = () => {
    if(connectButton[0]){
      connectButton[0].click();
      // console.log(connectButton);
    }
  }

  return (
    <div className="grid gap-8 bg-darkgry py-[6%] md:grid-cols-2 px-[5%] sm:px-[10%]">
      <div className="flex gap-8 flex-col">
          <div className="md:col-span-2 flex w-full">
            <div>
            <h1 className="md:text-[3.5vw] text-2xl pb-1  font-tragic">
              Get in Touch
            </h1>
            <p className="font-incon lg:text-[1.5vw] md:text-lg text-2xl font-medium pt-3">Have any related queries</p>
            </div>
          <div className="relative flex flex-col justify-center items-center" onClick={connectOpen}>
            <button className="absolute text-md md:text-[1.3vw] text-white pl-2 font-ver">
              Enquire Now
            </button>
            <img src="Assets/grp34.png" className="xl:w-[75%] md:w-[50%]" alt="" />
          </div>
          </div>
        <div>
          <div className="flex flex-wrap lg:text-[1.5vw] md:text-lg text-2xl pb-4 gap-2 lg:gap-4 font-incon font-medium">
          <NavLink to="/timeline" onClick={scrollToTop} className="hover:text-red"> Know your instructor</NavLink>
            <span className="border-r-2"></span>
            <div onClick={connectOpen} className="hover:text-red"> Schedule a call</div>
            <span className="border-r-2"></span>
            <NavLink to="/disclaimer" onClick={scrollToTop} className="hover:text-red"> Disclaimer</NavLink>
            <span className="border-r-2"></span>
          <NavLink to="/privacy-policy" onClick={scrollToTop} className="hover:text-red"> Privacy Policy</NavLink>
            <span className="border-r-2"></span>
            <NavLink to="/cookie-policy" onClick={scrollToTop} className="hover:text-red"> Cookies Policy</NavLink>
          </div>
        </div>
      </div>
      {/* <Divider orientation="vertical" /> */}
      <div className="flex justify-around items-center">
      <div className="flex flex-col gap-4">
        <div className="">
          <h1 className="md:text-[3.5vw] text-2xl pb-1 font-tragic">
            Contact Us
          </h1>
          <div className="flex items-center lg:text-[1.5vw] md:text-lg text-2xl font-incon pt-3">
            <MdLocationOn />
            <p className="pl-3 hover:text-red">
              Toronto, Canada
            </p>
          </div>
        </div>
        <div>
          {/* <div className="flex items-center md:text-[1.3vw] pb-4 font-incon">
            <IoIosCall className="" />
            <p className="pl-3">+91 78381 00463</p>
          </div> */}
          <a href="mailto:influencer.anks@gmail.com">
          <div className="flex items-center">
            <IoIosMail />
            <p className="pl-3 lg:text-[1.5vw] md:text-lg text-2xl font-incon hover:text-red">learnfrenchwithanks@gmail.com</p>
          </div>
          </a>
        </div>
      </div>
      <div>
        <NavLink to="/" onClick={scrollToTop}>
        <img className="h-[55%] w-[55%]" src="/Assets/logo.png" alt="" />
        </NavLink>
      </div>
    </div>
      </div>
  );
};

export default Footer;
