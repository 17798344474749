import React from "react";
const CookiePolicy = () => {
  return (
    <div
      style={{
        backgroundImage: `url('/Assets/bg1.png')`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
      className="px-[12%] lg:py-[10%] pb-[20%] pt-[30%]  relative bg-secondary"
    >
      <h1 className="text-3xl font-bold mb-6 text-blue">
        Cookies Policy for Learn French with Anks
      </h1>
      <p className="mb-4 text-justify">
        <strong>Effective Date:</strong> December 5, 2024
      </p>
      <p className="mb-4 text-justify">
        At Learn French with Anks, accessible from{" "}
        <a className="text-blue" href="https://www.learnfrenchwithanks.com/">
          https://www.learnfrenchwithanks.com/
        </a>
        , we use cookies to enhance your browsing experience and improve our
        services. This Cookies Policy explains what cookies are, how we use
        them, and your rights regarding their usage.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        1. What Are Cookies?
      </h2>
      <p className="mb-4 text-justify">
        Cookies are small text files placed on your device (computer,
        smartphone, tablet) when you visit a website. They help websites
        remember information about your visit, such as your preferred language,
        login details, or browsing preferences, to improve your overall
        experience.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        {" "}
        2. Types of Cookies We Use
      </h2>
      <p className="mb-4 text-justify">
        We use the following types of cookies on our website:
        <ul className="list-disc ml-6 mb-4">
          <li>
            Necessary Cookies: These cookies are essential for the website to
            function properly. They allow you to navigate the site and use its
            features, such as accessing secure areas.
          </li>
          <li>
            Performance Cookies: These cookies collect information about how
            visitors use our website, such as which pages are visited most
            often. This data helps us improve the website’s performance and user
            experience.
          </li>
          <li>
            Functional Cookies: Functional cookies remember your preferences,
            such as your language selection or customized settings, to provide
            you with a more personalized experience.
          </li>
          <li>
            Analytics Cookies: We use third-party analytics tools like Google
            Analytics to understand how users interact with our website. These
            cookies collect information about your browsing behavior, but the
            data is anonymized and does not identify you personally.
          </li>
        </ul>
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        3. Third-Party Cookies
      </h2>
      <p className="mb-4 text-justify">
        Some cookies may be placed by third-party services we use, such as
        payment processors, social media platforms, or analytics providers.
        These cookies are governed by the respective third parties’ privacy
        policies.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        {" "}
        4. Why We Use Cookies
      </h2>
      <p className="mb-4 text-justify">
        We use cookies to:
        <li> Provide essential website functionality.</li>
        <li> Enhance user experience by remembering your preferences.</li>
        <li>Analyze website traffic and usage to improve our services.</li>
        <li> Facilitate secure payment transactions on our course store.</li>
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        {" "}
        5. Managing Your Cookie Preferences
      </h2>
      <p className="mb-4 text-justify">
        You can manage or delete cookies through your browser settings. Most
        browsers allow you to:
        <li> Block cookies from specific websites.</li>
        <li> Delete cookies when you close your browser.</li>
        <li>Receive alerts before cookies are saved on your device.</li>
        Please note that disabling cookies may affect the functionality of our
        website and your user experience.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">
        {" "}
        6. Updates to This Cookies Policy
      </h2>
      <p className="mb-4 text-justify">
        We may update this Cookies Policy from time to time to reflect changes
        in our practices or for other operational, legal, or regulatory reasons.
        We encourage you to review this page periodically for any updates.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue">Contact Us</h2>
      <p className="mb-4 text-justify">
        If you have any questions about this Cookies Policy or how we use
        cookies, please contact us at:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Email: <strong>learnfrenchwithanks@gmail.com</strong>
        </li>
        <li>
          Website:{" "}
          <a href="https://www.learnfrenchwithanks.com">
            <strong>https://www.learnfrenchwithanks.com</strong>
          </a>
        </li>
      </ul>
      By continuing to use our website, you agree to the use of cookies as
      described in this policy.
    </div>
  );
};

export default CookiePolicy;
